export default {
  namespaced: true,
  state: {
    passport: '',
    token: '',
    refreshToken: ''
  },
  mutations: {
    SET_AUTH(state, options) {
      state.passport = options.passport
      state.token = options.token
      state.refreshToken = options.refreshToken
    }
  },
  actions: {
    setAuth(context, options) {
      context.commit('SET_AUTH', {
        passport: options.passport,
        token: options.token,
        refreshToken: options.refreshToken
      })
    }
  }
}
