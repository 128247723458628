<template>
  <div id="topnav">
    <div class="container d-flex flex-wrap">
      <ul class="nav me-auto"></ul>

      <ul class="nav">
        <!-- <li class="nav-item">
          <div class="input-group">
            <input type="text" class="form-control" placeholder="關鍵字搜尋" />
            <button type="button" class="input-group-text">
              <font-awesome-icon icon="search" />
            </button>
          </div>
        </li> -->
        <!-- <li v-if="$store.state.auth.passport == ''" class="nav-item">
          <router-link to="/member" class="nav-link link-dark px-2"
            ><font-awesome-icon icon="user" /> 會員登入/註冊</router-link
          >
        </li> -->
        <li v-if="$store.state.auth.passport != ''" class="nav-item">
          <a href="#" class="nav-link link-dark px-2" @click="ClickLogout()"
            ><font-awesome-icon icon="user" /> {{ $store.state.auth.passport }}登出</a
          >
        </li>
        <li class="nav-item">
          <a
            href="https://www.facebook.com/560629003977810/"
            class="nav-link link-dark px-2"
            target="_blank"
            ><font-awesome-icon :icon="['fab', 'facebook-square']" /> FB粉專</a
          >
        </li>
        <li class="nav-item">
          <a href="http://csw.shu.edu.tw/" class="nav-link link-dark px-2" target="_blank"
            ><font-awesome-icon icon="caret-right" /> 舍我紀念館</a
          >
        </li>
        <li class="nav-item">
          <a href="https://cjc.shu.edu.tw/" class="nav-link link-dark px-2" target="_blank"
            ><font-awesome-icon icon="caret-right" /> 新聞傳播學院</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { AuthenticateAPI } from '@/api/index'

export default {
  methods: {
    async ClickLogout() {
      let loader = this.$loading.show()
      await AuthenticateAPI.Revoke({ RefreshToken: this.$store.state.auth.refreshToken })
      this.$store.dispatch('auth/setAuth', {
        passport: '',
        token: '',
        refreshToken: ''
      })
      this.$swal({
        title: '',
        text: '已完成登出',
        icon: 'success'
      })
      loader.hide()
    }
  }
}
</script>

<style>
#topnav {
  background: #a4a9d5;
  padding: 0.5rem;
}

#topnav svg {
  color: #3645c7;
}

#topnav .input-group-text {
  background: #fff;
  border-left: none;
}

#topnav .input-group-text .form-control {
  border-right: none;
}

#topnav .nav-item:first-child {
  margin-right: 1rem;
}

#topnav .nav-item:last-child .nav-link {
  padding-right: 0 !important;
}
</style>
