<template>
  <header>
    <div class="container d-flex flex-wrap justify-content-center py-3">
      <router-link
        to="/"
        class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none"
        ><span class="brand"
          >傳播研究與實踐<small>JOURNAL OF COMMUNICATION<br />RESEARCH AND PRACTICE</small></span
        ></router-link
      >
      <nav class="navbar navbar-expand-sm">
        <button class="navbar-toggler" type="button">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse">
          <ul class="nav">
            <li class="nav-item">
              <router-link
                to="/news"
                class="nav-link"
                :class="{ active: subIsActive('/news', isShow1) }"
                @click="ClickNav(1)"
                >最新消息<span>News</span></router-link
              >
            </li>
            <li class="nav-item">
              <a
                href="#"
                class="nav-link"
                :class="{ active: subIsActive('/about', isShow2) }"
                @click="ClickNav(2)"
                >關於期刊<span>About</span></a
              >
              <div class="dropdown-menu" :class="{ show: isShow2 }">
                <router-link to="/about/purpose" class="dropdown-item" @click="ResetNav()"
                  >出版宗旨</router-link
                >
                <router-link to="/about/history" class="dropdown-item" @click="ResetNav()"
                  >出刊歷史</router-link
                >
                <router-link to="/about/creator" class="dropdown-item" @click="ResetNav()"
                  >創站團隊</router-link
                >
                <router-link to="/about/users" class="dropdown-item" @click="ResetNav()"
                  >人員編制</router-link
                >
                <router-link to="/about/chiefs" class="dropdown-item" @click="ResetNav()"
                  >歷屆主編</router-link
                >
              </div>
            </li>
            <li class="nav-item">
              <a
                href="#"
                class="nav-link"
                :class="{ active: subIsActive('/publishing', isShow3) }"
                @click="ClickNav(3)"
                >編輯與發行<span>Editors & Publishing</span></a
              >
              <div class="dropdown-menu" :class="{ show: isShow3 }">
                <router-link to="/publishing/committee" class="dropdown-item" @click="ResetNav()"
                  >編輯委員會</router-link
                >
                <router-link to="/publishing/policy" class="dropdown-item" @click="ResetNav()"
                  >編輯政策</router-link
                >
                <router-link to="/publishing/process" class="dropdown-item" @click="ResetNav()"
                  >行政流程</router-link
                >
                <router-link to="/publishing/issued" class="dropdown-item" @click="ResetNav()"
                  >發行資訊</router-link
                >
              </div>
            </li>
            <li class="nav-item">
              <a
                href="#"
                class="nav-link"
                :class="{ active: subIsActive('/guidelines', isShow4) }"
                @click="ClickNav(4)"
                >投稿須知<span>Author Guidelines</span></a
              >
              <div class="dropdown-menu" :class="{ show: isShow4 }">
                <router-link to="/guidelines/callpaper" class="dropdown-item" @click="ResetNav()"
                  >徵稿啟事</router-link
                >
                <router-link to="/guidelines/reviewer" class="dropdown-item" @click="ResetNav()"
                  >審稿程序</router-link
                >
                <router-link to="/guidelines/project" class="dropdown-item" @click="ResetNav()"
                  >專題徵稿</router-link
                >
                <router-link to="/guidelines/dowloads" class="dropdown-item" @click="ResetNav()"
                  >檔案下載</router-link
                >
              </div>
            </li>
            <li class="nav-item">
              <a
                href="https://toaj.stpi.narl.org.tw/index/journal/4b1141f97ce46933017ce469b62a0022"
                class="nav-link"
                target="_blank"
                >線上投稿<span>Submission Site</span></a
              >
            </li>
            <li class="nav-item">
              <a
                href="#"
                class="nav-link"
                :class="{ active: subIsActive('/archives', isShow6) }"
                @click="ClickNav(6)"
                >前期出版<span>Archives</span></a
              >
              <div class="dropdown-menu" :class="{ show: isShow6 }">
                <router-link to="/archives/current" class="dropdown-item" @click="ResetNav()"
                  >當期出版</router-link
                >
                <router-link to="/archives/preview" class="dropdown-item" @click="ResetNav()"
                  >歷期出版</router-link
                >
              </div>
            </li>
            <li class="nav-item">
              <router-link
                to="/comm101"
                class="nav-link"
                :class="{ active: subIsActive('/comm101', isShow7) }"
                @click="ClickNav(7)"
                >科普傳播<span>COMM 101</span></router-link
              >
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      isShow1: false,
      isShow2: false,
      isShow3: false,
      isShow4: false,
      isShow5: false,
      isShow6: false,
      isShow7: false
    }
  },
  methods: {
    ClickNav(i) {
      if (!this['isShow' + i]) {
        this.ResetNav()
      }
      this['isShow' + i] = !this['isShow' + i]
    },
    ResetNav() {
      for (let index = 1; index <= 7; index++) {
        this['isShow' + index] = false
      }
    },
    subIsActive(input, isShow) {
      const paths = Array.isArray(input) ? input : [input]
      return paths.some(path => {
        return this.$route.path.indexOf(path) === 0 || isShow
      })
    }
  }
}
</script>

<style>
header {
  background: #f1f1f1;
  border-bottom: 2px solid #fff;
  line-height: 1.3;
}

.brand {
  font-size: 1.8rem;
  font-weight: bolder;
}

.brand small {
  display: block;
  font-size: 0.6rem;
  font-weight: normal;
}

header .nav-link {
  font-size: 1.2rem;
  font-weight: bolder;
  color: #000;
  padding: 0.5rem 0;
  transition: all 0.1s linear;
  border-bottom: 3px solid transparent;
}

header .nav-item {
  margin-left: 2rem;
}

header .nav-link.active,
header .nav-link:hover {
  color: #3645c7;
  border-bottom: 3px solid #3645c7;
}

header .nav-link span {
  display: block;
  font-size: 0.6rem;
  font-weight: normal;
  color: #000;
}

header .dropdown-menu {
  min-width: 8rem;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
</style>
