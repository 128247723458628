import axios from 'axios'
import router from '../router'
import store from './store.js'

// API Server
const apiServer = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

// 全局設定 AJAX Request 攔截器 (interceptor)
apiServer.interceptors.request.use(
  config => {
    const token = store.state.auth.token
    token && (config.headers.Authorization = 'Bearer ' + token)
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// 全局設定 AJAX Response 攔截器 (interceptor)
apiServer.interceptors.response.use(
  response => {
    return response
  },
  error => {
    const { response } = error
    if (response) {
      switch (response.status) {
        case 401:
          // 當不是 refresh token 作業發生 401 才需要更新 access token 並重發
          // 如果是就略過此刷新 access token 作業，直接不處理(因為 catch 已經攔截處理更新失敗的情況了)
          if (
            0 > response.config.url.indexOf('/refresh') &&
            0 > response.config.url.indexOf('/authenticate')
          ) {
            // 原始 request 資訊
            const originalRequest = response.config
            return AuthenticateAPI.Refresh({ RefreshToken: store.state.auth.refreshToken })
              .then(response => {
                // [更新 access_token 成功]
                // 刷新 storage (其他呼叫 api 的地方都會從此處取得新 access_token)
                store.dispatch('auth/setAuth', {
                  passport: response.data.passport,
                  token: response.data.token,
                  refreshToken: response.data.refreshToken
                })
                // 更新JWT
                originalRequest.headers.Authorization = 'Bearer ' + response.data.token
                return axios(originalRequest)
              })
              .catch(err => {
                // [更新 access_token 失敗] ( e.g. refresh_token 過期無效)
                store.dispatch('auth/setAuth', {
                  passport: '',
                  token: '',
                  refreshToken: ''
                })
                router.push({ name: 'Home' })
              })
          }

          store.dispatch('auth/setAuth', {
            passport: '',
            token: '',
            refreshToken: ''
          })
          router.push({ name: 'Home' })
          break
      }
      return Promise.reject(response)
    }
  }
)

const errorHandle = response => {}

export const AuthenticateAPI = {
  Check: data => apiServer.post('Login', data),
  Refresh: data => apiServer.post('Login/refresh', data),
  Revoke: data => apiServer.post('Login/revoke', data)
}

export const PublicAPI = {
  Home: () => apiServer.get('Public/Home'),
  Chapter: id => apiServer.get('Public/Chapter/' + id),
  Messages: page => apiServer.get('Public/Message/page/' + page),
  Message: id => apiServer.get('Public/Message/' + id),
  Download: () => apiServer.get('Public/Download'),
  Comms: page => apiServer.get('Public/Comm/page/' + page),
  Comm: id => apiServer.get('Public/Comm/' + id),
  NewJournal: () => apiServer.get('Public/Journal/Lastest'),
  Journal: id => apiServer.get('Public/Journal/' + id),
  Journals: page => apiServer.get('Public/Journals/page/' + page),
  Paper: id => apiServer.get('Public/Paper/' + id),
  PaperFile: id => apiServer.get('AppendFile/Paper/' + id, { responseType: 'blob' }),
  Submits: data => apiServer.post('Public/Submit', data),
  Register: data => apiServer.post('Public/Register', data)
}
