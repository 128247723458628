<template>
  <carousel :autoplay="5000" :items-to-show="1" :wrap-around="true">
    <slide v-for="(item, index) in items" :key="index">
      <a :href="getLink(item.link)" :title="item.subject"
        ><img :src="item.fileUrl" class="img-fluid" :alt="item.subject"
      /></a>
    </slide>
    <template #addons>
      <navigation />
    </template>
  </carousel>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'

export default {
  components: {
    Carousel,
    Slide,
    Navigation
  },
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    }
  },
  methods: {
    getLink(url) {
      return url == '' ? 'javascript:void(0)' : url
    }
  }
}
</script>

<style>
.carousel__prev {
  left: 30px;
}

.carousel__next {
  right: 30px;
}
</style>
