<template>
  <carousel-zone v-show="carousels.length > 0" :items="carousels"></carousel-zone>
  <div class="content">
    <new-paper :items="papers"></new-paper>
  </div>
</template>

<script>
import { PublicAPI } from '@/api/index'
import 'vue3-carousel/dist/carousel.css'
import CarouselZone from '@/components/home/Carousel.vue'
import NewPaper from '@/components/home/NewPaper.vue'

export default {
  name: 'Home',
  components: {
    CarouselZone,
    NewPaper
  },
  data() {
    return {
      carousels: [],
      papers: []
    }
  },
  mounted() {
    this.contentList()
  },
  methods: {
    async contentList() {
      let loader = this.$loading.show()
      await PublicAPI.Home()
        .then(response => {
          this.carousels = response.data.carousels
          this.papers = response.data.papers
        })
        .catch(err => {
          console.log(err)
        })
      loader.hide()
    }
  }
}
</script>

<style>
.carousel {
  background: #f1f1f1;
}
</style>
