import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import auth from './auth.js'

const store = createStore({
  modules: {
    auth
  },
  plugins: [
    createPersistedState({
      storage: window.localStorage,
      reducer(val) {
        return {
          auth: val.auth
        }
      }
    })
  ]
})

export default store
