<template>
  <footer>
    <div class="container">
      <p class="title">世新大學新聞傳播學院</p>
      <div class="d-flex flex-wrap justify-content-between align-items-center">
        <ul id="information" class="list-unstyled">
          <li><font-awesome-icon icon="phone" />+886-2-2236-8225 #83112</li>
          <li><font-awesome-icon icon="map-marker-alt" />11604 台北市文山區木柵路一段17巷1號</li>
          <li><font-awesome-icon icon="envelope" />crpjour@gmail.com</li>
        </ul>

        <div id="copyright">
          <p>
            © {{ new Date().getFullYear() }} by the Editorial Committee, College of Journalism and
            Communications.<br />Some rights reserved, authorized under creative Commons license
            clauses.<br />Journal of Communication Research and Practice.
          </p>
          <p>
            本網站獲110年度科技部人文社會科學研究中心補助<br />Best viewed with higher versions of
            Google Chrome, Mozilla Firefox, Safari, Opera and Microsoft Edge.<br />本網站建議以Google
            Chrome、Mozilla Firefox、 Safari、Opera 或 Microsoft Edge瀏覽器瀏覽
          </p>
        </div>

        <p class="justify-content-end">
          <img src="@/assets/images/creative.png" alt="" />
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {}
</script>

<style>
footer {
  color: #3b3e58;
  background: #a4a9d5;
  padding: 3rem 0;
}

footer svg {
  width: 20px !important;
  margin-right: 8px;
}

footer .title {
  font-weight: bolder;
  font-size: 1.2rem;
  border-bottom: 1px solid #fff;
  padding-bottom: 0.4rem;
}

#information {
  font-size: 0.9rem;
}

#information li {
  margin-bottom: 0.6rem;
}

#copyright {
  font-size: 0.6rem;
  letter-spacing: 0;
}
</style>
