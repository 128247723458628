<template>
  <div class="container">
    <h1>最新論文</h1>
    <div v-for="(item, index) in items" :key="index" class="card-paper">
      <div class="card">
        <div class="me-auto"><font-awesome-icon icon="file-alt" /></div>
        <router-link class="card-body" :to="'/archives/article/' + item.id" :title="item.subject">
          <p class="paper-title" v-text="item.subject"></p>
          <p class="paper-title-eng" v-text="item.subjectEng"></p>
          <p class="paper-author">
            {{ item.author }} <small class="text-muted" v-text="item.authorEng"></small>
          </p>
          <p class="paper-text text-muted">關鍵字：{{ item.keyword }}</p>
          <p class="paper-text text-muted">Keywords: {{ item.keywordEng }}</p></router-link
        >
      </div>
      <div class="line"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default() {
        return []
      }
    }
  }
}
</script>

<style>
.card-paper {
  padding: 1rem 0;
}

.card-paper .card {
  flex-direction: row;
  border: none;
}

.card-paper svg {
  font-size: 4rem;
  color: #e4e5ef;
  margin-top: 60px;
}

.card-paper .me-auto {
  width: 200px !important;
  height: 180px;
  text-align: center;
  overflow: hidden;
}

.line {
  width: 100%;
  height: 1px;
  background-image: linear-gradient(to right, #d8d8d8 0%, #d8d8d8 50%, transparent 50%);
  background-size: 12px 1px;
  background-repeat: repeat-x;
  margin: 1rem 0 -1rem;
}

.paper-title {
  color: #3b3e58;
  font-size: 1.5rem;
  font-weight: bolder;
  margin-bottom: 0;
}

.paper-title-eng {
  color: #3b3e58;
  letter-spacing: 0;
  font-size: 0.9rem;
  margin-bottom: 0.4rem;
}

.paper-author {
  font-size: 1.3rem;
  font-weight: bolder;
  color: #3645c7;
}

.paper-text {
  margin: 0;
  letter-spacing: 0;
  font-size: 0.9rem;
}
</style>
