import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: process.env.VUE_APP_TITLE
    }
  },
  {
    path: '/news',
    component: () => import('../views/ContainerFulid.vue'),
    meta: {
      nav: '最新消息',
      navEng: 'News',
      navs: []
    },
    children: [
      {
        path: '',
        component: () => import('../views/message/List.vue')
      },
      {
        path: ':code',
        component: () => import('../views/message/Data.vue')
      }
    ]
  },
  {
    path: '/about',
    component: () => import('../views/Container.vue'),
    meta: {
      nav: '關於期刊',
      navEng: 'About',
      navs: [
        { link: '/about/purpose', name: '出版宗旨' },
        { link: '/about/history', name: '出刊歷史' },
        { link: '/about/creator', name: '創站團隊' },
        { link: '/about/users', name: '人員編制' },
        { link: '/about/chiefs', name: '歷屆主編' }
      ]
    },
    children: [
      {
        path: '',
        redirect: { name: 'purpose' }
      },
      {
        path: 'purpose',
        name: 'purpose',
        component: () => import('../views/modules/Chapter.vue'),
        meta: {
          title: '出版宗旨',
          code: 'kuZFhfCPdVv'
        }
      },
      {
        path: 'history',
        name: 'history',
        component: () => import('../views/modules/Chapter.vue'),
        meta: {
          title: '出刊歷史',
          code: 'C9r5nPG8JxI'
        }
      },
      {
        path: 'creator',
        name: 'creator',
        component: () => import('../views/modules/Chapter.vue'),
        meta: {
          title: '創站團隊',
          code: 'UjJZgb48zOK'
        }
      },
      {
        path: 'users',
        name: 'users',
        component: () => import('../views/modules/Chapter.vue'),
        meta: {
          title: '人員編制',
          code: 'FmXizMhx3tb'
        }
      },
      {
        path: 'chiefs',
        name: 'chiefs',
        component: () => import('../views/modules/Chapter.vue'),
        meta: {
          title: '歷屆主編',
          code: 'KyolI5kRpxr'
        }
      }
    ]
  },
  {
    path: '/publishing',
    component: () => import('../views/Container.vue'),
    meta: {
      nav: '編輯與發行',
      navEng: 'Editors & Publishing',
      navs: [
        { link: '/publishing/committee', name: '編輯委員會' },
        { link: '/publishing/policy', name: '編輯政策' },
        { link: '/publishing/process', name: '行政流程' },
        { link: '/publishing/issued', name: '發行資訊' }
      ]
    },
    children: [
      {
        path: '',
        redirect: { name: 'committee' }
      },
      {
        path: 'committee',
        name: 'committee',
        component: () => import('../views/modules/Chapter.vue'),
        meta: {
          title: '編輯委員會',
          code: 't9q5c704PDX'
        }
      },
      {
        path: 'policy',
        name: 'policy',
        component: () => import('../views/modules/Chapter.vue'),
        meta: {
          title: '編輯政策',
          code: 'J1e9BK5Dbqd'
        }
      },
      {
        path: 'process',
        name: 'process',
        component: () => import('../views/modules/Chapter.vue'),
        meta: {
          title: '行政流程',
          code: 'xbo1LdZJyfF'
        }
      },
      {
        path: 'issued',
        name: 'issued',
        component: () => import('../views/modules/Chapter.vue'),
        meta: {
          title: '發行資訊',
          code: 'Pf1r2NY74xU'
        }
      }
    ]
  },
  {
    path: '/guidelines',
    component: () => import('../views/Container.vue'),
    meta: {
      nav: '投稿須知',
      navEng: 'Author Guidelines',
      navs: [
        { link: '/guidelines/callpaper', name: '徵稿啟事' },
        { link: '/guidelines/reviewer', name: '審稿程序' },
        { link: '/guidelines/project', name: '專題徵稿' },
        { link: '/guidelines/dowloads', name: '檔案下載' }
      ]
    },
    children: [
      {
        path: '',
        redirect: { name: 'callpaper' }
      },
      {
        path: 'callpaper',
        name: 'callpaper',
        component: () => import('../views/modules/Chapter.vue'),
        meta: {
          title: '徵稿啟事',
          code: '1iB9y5PjlAS'
        }
      },
      {
        path: 'reviewer',
        name: 'reviewer',
        component: () => import('../views/modules/Chapter.vue'),
        meta: {
          title: '審稿程序',
          code: 'T2MWurVygJG'
        }
      },
      {
        path: 'project',
        name: 'project',
        component: () => import('../views/modules/Chapter.vue'),
        meta: {
          title: '專題徵稿',
          code: 'PpTVD7LJqB6'
        }
      },
      {
        path: 'dowloads',
        name: 'dowloads',
        component: () => import('../views/Download.vue'),
        meta: {
          title: '檔案下載',
          code: ''
        }
      }
    ]
  },
  {
    path: '/submission',
    component: () => import('../views/ContainerFulid.vue'),
    meta: {
      nav: '線上投稿',
      navEng: 'Submission Site',
      navs: []
    },
    children: [
      {
        path: '',
        component: () => import('../views/submits/Form.vue')
      }
    ]
  },
  {
    path: '/archives',
    component: () => import('../views/ContainerFulid.vue'),
    meta: {
      nav: '前期出版',
      navEng: 'Archives',
      navs: [
        { link: '/archives/current', name: '當期出版' },
        { link: '/archives/preview', name: '歷期出版' }
      ]
    },
    children: [
      {
        path: '',
        redirect: { name: 'current' }
      },
      {
        path: 'current',
        name: 'current',
        component: () => import('../views/journals/Lastest.vue'),
        meta: {
          title: '當期出版'
        }
      },
      {
        path: 'article/:code',
        component: () => import('../views/journals/Paper.vue')
      },
      {
        path: 'journal/:code',
        component: () => import('../views/journals/Journal.vue')
      },
      {
        path: 'preview',
        name: 'preview',
        component: () => import('../views/journals/List.vue'),
        meta: {
          title: '歷期出版'
        }
      }
    ]
  },
  {
    path: '/comm101',
    component: () => import('../views/ContainerFulid.vue'),
    meta: {
      nav: '科普傳播',
      navEng: 'COMM 101',
      navs: []
    },
    children: [
      {
        path: '',
        component: () => import('../views/comm/List.vue')
      },
      {
        path: ':code',
        component: () => import('../views/comm/Data.vue')
      }
    ]
  },
  {
    path: '/member',
    component: () => import('../views/ContainerFulid.vue'),
    children: [
      {
        name: 'login',
        path: '',
        meta: {
          nav: '登入',
          navEng: 'Login',
          navs: []
        },
        component: () => import('../views/members/Login.vue')
      },
      {
        name: 'register',
        path: 'register',
        meta: {
          nav: '會員註冊',
          navEng: 'Member',
          navs: []
        },
        component: () => import('../views/members/Form.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASEFOLDER),
  linkActiveClass: 'active',
  routes
})

export default router
