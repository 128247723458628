import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './api/store.js'

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

// fontawesome
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons'
import {
  faUser,
  faCaretRight,
  faCheck,
  faSearch,
  faPhone,
  faMapMarkerAlt,
  faEnvelope,
  faFileAlt,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons'
library.add(faFacebookSquare)
library.add(faUser)
library.add(faCaretRight)
library.add(faCheck)
library.add(faSearch)
library.add(faPhone)
library.add(faMapMarkerAlt)
library.add(faEnvelope)
library.add(faFileAlt)
library.add(faChevronRight)

// sweetalert
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

// loading-overlay
import VueLoading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// vue-easy-lightbox
import VueEasyLightbox from 'vue-easy-lightbox'

// GA
import VueGtag from 'vue-gtag'

createApp(App)
  .use(router)
  .use(store)
  .use(VueSweetalert2)
  .use(VueLoading, { loader: 'dots', color: '#87CEFA' })
  .use(VueEasyLightbox)
  .use(VueGtag, {
    config: {
      id: 'G-9RGG0BRCZ5'
    }
  })
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app')
