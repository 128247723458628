<template>
  <CiaoyuTop />
  <CiaoyuHeader />
  <main>
    <router-view />
  </main>
  <CiaoyuFooter />
</template>

<script>
import CiaoyuTop from '@/components/layout/Topnav.vue'
import CiaoyuHeader from '@/components/layout/Header.vue'
import CiaoyuFooter from '@/components/layout/Footer.vue'

import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/theme.css'

export default {
  components: {
    CiaoyuTop,
    CiaoyuHeader,
    CiaoyuFooter
  }
}
</script>
